<template>
  <div>
    <title-bar :title-stack="titleStack" />
    <hero-bar>
      Profile
      <router-link slot="right" to="/" class="button">
        Dashboard
      </router-link>
    </hero-bar>
    <section class="section is-main-section">
      <tiles>
        <card-component title="Edit Profile" icon="account-circle" class="tile is-child">
          <form @submit.prevent="submit">
            <b-field label="NID" horizontal>
              <b-input
                type="number"
                placeholder="NID"
                v-model="form.noid"
                required
              />
            </b-field>
            <b-field label="Nama" horizontal>
              <b-input
                v-model="form.name"
                placeholder="e.g. John Doe"
                required
              />
            </b-field>
            <b-field label="Email" horizontal>
              <b-input
                type="email"
                v-model="form.email"
                placeholder=""
                required
              />
            </b-field>
            <b-field label="Kontak" horizontal>
              <b-input
                type="number"
                v-model="form.phone"
              />
            </b-field>

            <b-field horizontal label="Tanda Tangan">
              <b-upload v-model="file">
                <a class="button is-primary">
                  <b-icon icon="upload" custom-size="default"></b-icon>
                  <span>{{ buttonLabel }}</span>
                </a>
              </b-upload>
              <span v-if="file" class="file-name">{{ file.name }}</span>
            </b-field>
            <hr />
            <b-field horizontal>
              <div class="control">
                <button
                  type="submit"
                  class="button is-primary"
                  :class="{ 'is-loading': isLoading }"
                >
                  Submit
                </button>
              </div>
            </b-field>
          </form>
        </card-component>

        <card-component title="Profile" icon="account" class="tile is-child">
          <b-field label="NID">
            <b-input :value="form.noid" custom-class="is-static" readonly />
          </b-field>
          <b-field label="Name">
            <b-input :value="form.name" custom-class="is-static" readonly />
          </b-field>
          <b-field label="Email">
            <b-input :value="form.email" custom-class="is-static" readonly />
          </b-field>
          <b-field label="Kontak">
            <b-input :value="form.phone" custom-class="is-static" readonly />
          </b-field>
          <b-field label="Created">
            <b-input
              :value="form.created_at"
              custom-class="is-static"
              readonly
            />
          </b-field>
          <hr />
          <user-avatar
            v-if="form.sign"
            :avatar="form.sign"
            class="image has-max-width is-aligned-center"
          />
        </card-component>
      </tiles>
      <password-update-form />
    </section>
  </div>
</template>

<script>
import CardComponent from '@/components/CardComponent'
import TitleBar from '@/components/TitleBar'
import HeroBar from '@/components/HeroBar'
import PasswordUpdateForm from '@/components/PasswordUpdateForm'
import Tiles from '@/components/Tiles'
import UserAvatar from '@/components/UserAvatar'
import axios from 'axios'

export default {
  name: 'Profile',
  components: {
    UserAvatar,
    Tiles,
    PasswordUpdateForm,
    HeroBar,
    TitleBar,
    CardComponent
  },
  data () {
    return {
      form: {},
      file: null,
      isFileUploaded: false,
      isLoading: false
    }
  },
  created () {
    const id = parseInt(this.$store.state.user.user.id)
    axios
      .get(`/user/${id}`)
      .then((r) => {
        console.log(r.data)
        if (r.data.status === 'ok') {
          this.form = r.data.data
          this.isProfileExists = true
        }
      })
      .catch((e) => {
        this.$buefy.toast.open({
          message: `Error: ${e.message}`,
          type: 'is-danger',
          queue: false
        })
      })
  },
  computed: {
    buttonLabel () {
      return !this.file ? 'Pick a file' : 'Pick another file'
    },
    user_id () {
      return this.$store.state.user.user.id
    },
    titleStack () {
      return ['Admin', 'Profile']
    }
  },
  methods: {
    submit () {
      const data = new FormData()
      data.append('noid', this.form.noid)
      data.append('name', this.form.name)
      data.append('email', this.form.email)
      data.append('phone', this.form.phone)

      if (this.file) {
        data.append('file', this.file)
      }

      this.isLoading = true

      if (this.user_id) {
        axios
          .post(`/update-profile/${this.user_id}`, data,
            {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            }
          )
          .then(this.handleResponse)
          .catch(this.handleError)
      }
    },
    handleResponse (r) {
      console.log(r.data)
      this.isLoading = false

      this.$buefy.snackbar.open({
        message: (typeof r.data.message === 'object') ? `error: ${r.data.message.join(', ')}` : r.data.message,
        type: r.data.status === 'ok' ? 'is-primary' : 'is-danger',
        queue: false
      })
    },
    handleError (e) {
      this.isLoading = false
      this.$buefy.toast.open({
        message: `Error: ${e.message}`,
        type: 'is-danger',
        queue: false
      })
    }
  }
}
</script>
